<template>
  <div class="employee-replacement">
    <esmp-loader
      v-show="loading"
      size="large"
      fix
    />
    <div
      v-if="showEmployee"
      :class="[
        'employee-replacement__element',
        { 'hm-form__element-wrapper--error': hasError && !employee }
      ]"
    >
      <hm-search
        search-type="byEmployee"
        placeholder="ФИО сотрудника, для кого изменится местоположение"
        @input="setEmployee"
      />
    </div>

    <div
      v-if="showPlacementType"
      :class="[
        'employee-replacement__element',
        { 'hm-form__element-wrapper--error': hasError && !placementType }
      ]"
    >
      <esmp-select
        placeholder="Тип размещения"
        :disabled="isPlacementTypeDisabled"
        filterable
        v-model="placementType"
      >
        <esmp-select-option
          v-for="item in placementTypeList"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
    </div>

    <div
      :class="[
        'employee-replacement__element',
        { 'hm-form__element-wrapper--error': hasError && !activeRegion }
      ]"
    >
      <esmp-select
        placeholder="Регион"
        :disabled="isRegionDisabled"
        filterable
        v-model="activeRegion"
      >
        <esmp-select-option
          v-for="item in regions"
          :key="item.name"
          :value="item.name"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
    </div>

    <div
      :class="[
        'employee-replacement__element',
        { 'hm-form__element-wrapper--error': hasError && !(hasAddressProblem ? address : activeAddress) }
      ]"
    >
      <esmp-select
        v-if="!hasAddressProblem"
        placeholder="Адрес"
        :disabled="isAddressDisabled"
        filterable
        v-model="activeAddress"
      >
        <esmp-select-option
          v-for="item in addresses"
          :key="item.name"
          :value="item.name"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
      <esmp-input
        v-else
        label="Введите адрес"
        :disabled="isAddressDisabled"
        v-model="address"
      />
    </div>
    <div v-if="addressChoiceTrouble" class="employee-replacement__element">
      <esmp-checkbox
        :disabled="hasRoomProblem && isAddressDisabled"
        v-model="hasAddressProblem"
      >
        У меня проблема с выбором улицы, дома
      </esmp-checkbox>
    </div>

    <div
      :class="[
        'employee-replacement__element',
        {
          'hm-form__element-wrapper--error': hasError
            && !(!hasAddressProblem && !hasRoomProblem ? activeFloor : floor)
        }
      ]"
    >
      <esmp-select
        v-if="!hasAddressProblem && !hasRoomProblem"
        placeholder="Этаж"
        :disabled="isFloorDisabled"
        filterable
        v-model="activeFloor"
      >
        <esmp-select-option
          v-for="item in floors"
          :key="item.name"
          :value="item.name"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
      <esmp-input
        v-else
        label="Введите этаж"
        :disabled="!address && isFloorDisabled"
        v-model="floor"
      />
    </div>
    <div v-if="roomChoiceTrouble" class="employee-replacement__element">
      <esmp-checkbox
        :disabled="hasAddressProblem && isRoomDisabled"
        v-model="hasRoomProblem"
      >
        У меня проблема с выбором этажа, комнаты
      </esmp-checkbox>
    </div>

    <div
      :class="[
        'employee-replacement__element',
        { 'hm-form__element-wrapper--error': hasError && !(!hasAddressProblem && !hasRoomProblem ? activeRoom : room) }
      ]"
    >
      <esmp-select
        v-if="!hasAddressProblem && !hasRoomProblem"
        placeholder="Комната"
        :disabled="isRoomDisabled"
        filterable
        v-model="activeRoom"
      >
        <esmp-select-option
          v-for="item in rooms"
          :key="item.name"
          :value="item.name"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
      <esmp-input
        v-else
        label="Введите номер комнаты"
        :disabled="!floor && isRoomDisabled"
        v-model="room"
      />
    </div>

    <div
      v-if="showPlacementNumber"
      :class="[
        'employee-replacement__element',
        { 'hm-form__element-wrapper--error': hasError && !workPlaceNumber }
      ]"
    >
      <esmp-select
        v-if="isWorkPlacesNotEmpty"
        v-model="workPlaceNumber"
        :disabled="isWorkPlaceNumberDisabled"
        placeholder="Номер рабочего места"
        filterable
      >
        <esmp-select-option
          v-for="item in workPlaces"
          :key="item.name"
          :value="item.name"
        >
          {{ item.name }}
        </esmp-select-option>
      </esmp-select>
    </div>

    <p v-if="false" class="employee-replacement__element">
      Если у Вас проблема с выбором адреса, этажа или кабинета, обратитесь,
      пожалуйста, к специалисту АХД своего региона. <br>
      Список специалистов по ссылке:
      <a
        href="https://w3c.portal.rt.ru/files/app#/file/2cce59d7-bbf7-47d0-bcbe-e9034f0096e5"
        target="_blank"
      >https://w3c.portal.rt.ru/files/app#/file/2cce59d7-bbf7-47d0-bcbe-e9034f0096e5</a>
    </p>

    <esmp-modal
      v-model="showModal"
      @on-hidden="clearEmployeeLocationsList"
      title="Выберите адрес размещения сотрудника"
      width="80%"
    >
      <employee-replacement-address-list
        :items="employeeLocationsList"
        @location-item-click="onLocationItemSelect"
      />

      <template #footer>
        <esmp-button @click="onLocationItemSelect">
          Выбрать
        </esmp-button>
      </template>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import HmSearch from '../HmSearch.vue';
import EmployeeReplacementAddressList from './EmployeeReplacementAddressList.vue';

// TODO: выводить, если более 1 закрепленного места, адрес ОТКУДА перемещают сотрудника

const PLACEMENT_TYPE = [
  {
    id: 'noWorkPlace',
    name: 'Без рабочего места',
  },
  {
    id: 'noFixedWorkPlace',
    name: 'Без закрепленного рабочего места',
  },
  {
    id: 'fixedWorkPlace',
    name: 'С закрепленным рабочим местом',
  },
  {
    id: 'fixedWorkPlaces',
    name: 'С закрепленными рабочими местами (более 1)',
  },
  {
    id: 'fixedCommonWorkPlace',
    name: 'С закрепленным общим рабочим местом',
  },
];

export default {
  name: 'EmployeeReplacement',
  model: {
    prop: 'value',
    event: 'change',
  },
  components: { EmployeeReplacementAddressList, HmSearch },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    replaceDirection: {
      type: String,
      default: '',
    },
    showEmployee: {
      type: Boolean,
      default: false,
    },
    showPlacementType: {
      type: Boolean,
      default: false,
    },
    showPlacementNumber: {
      type: Boolean,
      default: false,
    },
    roomChoiceTrouble: {
      type: Boolean,
      default: false,
    },
    addressChoiceTrouble: {
      type: Boolean,
      default: false,
    },
    notToValidateRelocationData: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      val: null,
      loading: false,
      employee: null,
      employeeLocationsList: [],
      placementType: null,
      availableFields: [],
      activeRegion: null,
      addresses: [],
      activeAddress: null,
      hasAddressProblem: false,
      address: '',
      floors: [],
      activeFloor: null,
      floor: '',
      rooms: [],
      activeRoom: null,
      hasRoomProblem: false,
      room: '',
      workPlaceNumber: null,
      workPlaces: [],
      showModal: false,
    };
  },
  computed: {
    ...mapState('employeeReplacement', ['regions']),
    placementTypeList() {
      if (this.notToValidateRelocationData) {
        return PLACEMENT_TYPE;
      }
      if (this.replaceDirection === 'to') {
        return PLACEMENT_TYPE.filter(
          (t) => t.id !== 'noWorkPlace' && t.id !== 'noFixedWorkPlace',
        );
      }

      return PLACEMENT_TYPE.filter((t) => t.id !== 'noWorkPlace');
    },

    isPlacementTypeDisabled() {
      return false;
    },
    isRegionDisabled() {
      if (this.replaceDirection === 'from') return false;
      if (this.showPlacementType) {
        if (isEmpty(this.placementType)) return true;
        const isAvailable = this.availableFields.includes('region');
        return !isAvailable;
      }
      return false;
    },
    isAddressDisabled() {
      if (this.replaceDirection === 'from') return false;
      if (this.showPlacementType) {
        if (isEmpty(this.placementType)) return true;
        const isAvailable = this.availableFields.includes('address');
        return !isAvailable && !this.activeRegion;
      }
      return !this.activeRegion;
    },
    isFloorDisabled() {
      if (this.replaceDirection === 'from') return false;
      if (this.showPlacementType) {
        if (isEmpty(this.placementType)) return true;
        const isAvailable = this.availableFields.includes('floor');
        return !isAvailable && !this.activeAddress;
      }
      return !this.activeAddress;
    },
    isRoomDisabled() {
      if (this.replaceDirection === 'from') return false;
      if (this.showPlacementType) {
        if (isEmpty(this.placementType)) return true;
        const isAvailable = this.availableFields.includes('room');
        return !isAvailable && !this.activeFloor;
      }
      return !this.activeFloor;
    },
    isWorkPlaceNumberDisabled() {
      if (this.replaceDirection === 'from') return false;
      if (this.showPlacementType) {
        if (isEmpty(this.placementType)) return true;
        const isAvailable = this.availableFields.includes('workPlace');
        return !isAvailable;
      }
      return false;
    },
    isWorkPlacesNotEmpty() {
      return !!this.workPlaces.length;
    },
  },
  methods: {
    ...mapActions('employeeReplacement', ['getRegions']),
    setEmployee(v) {
      // eslint-disable-next-line prefer-destructuring
      this.employee = v[0];
    },
    async findUserLocation(login) {
      this.loading = true;
      const employeeLocationsList = await this.$API.employeeReplace.getUserWorkPlaceInfo({
        email: login,
      }).then(({ data }) => {
        let places = [];
        if (data.places && data.places.length) {
          places = data.places.map((p) => ({
            ...p,
            placeType: data.placeType,
          }));
        } else {
          this.$EsmpNotify.$show('У выбранного сотрудника нет рабочего места', 'attention');
        }
        return places;
      }).finally(() => {
        this.setLocations(null);
        this.loading = false;
      });

      if (employeeLocationsList && employeeLocationsList.length) {
        if (employeeLocationsList.length > 1) {
          this.employeeLocationsList = employeeLocationsList;
          this.showModal = true;
        } else {
          this.setLocations(employeeLocationsList[0]);
        }
      } else {
        this.setLocations(null);
      }

      this.loading = false;
    },
    setVal() {
      const value = {
        employeeLogin:
          this.showEmployee && !isEmpty(this.employee)
            ? this.employee.login
            : null,
        placementType:
          this.showPlacementType && this.placementType
            ? this.placementType
            : null,
        region: this.activeRegion ? this.activeRegion : null,
        /* eslint-disable no-nested-ternary */
        address: this.hasAddressProblem
          ? this.address
          : this.activeAddress
            ? this.activeAddress
            : null,
        floor:
          this.hasAddressProblem || this.hasRoomProblem
            ? this.floor
            : this.activeFloor
              ? this.activeFloor
              : null,
        room:
          this.hasAddressProblem || this.hasRoomProblem
            ? this.room
            : this.activeRoom
              ? this.activeRoom
              : null,
        workPlace: this.workPlaceNumber,
        /* eslint-enable no-nested-ternary */
        hasRoomProblem: this.hasRoomProblem || false,
        hasAddressProblem: this.hasAddressProblem || false,
        replaceDirection: this.replaceDirection,
        isEnabledWorkPlace: this.showPlacementNumber,
      };

      this.$emit('change', value);
    },
    clearEmployeeLocationsList() {
      this.employeeLocationsList = [];
    },
    setLocations(location) {
      if (!location) {
        this.activeRegion = null;
        return;
      }
      if (location.placeType) {
        const placementType = this.placementTypeList.find(
          (pt) => pt.id === location.placeType,
        );
        if (placementType) {
          this.placementType = placementType.id;
        }
      }
      if (location.region) {
        this.activeRegion = `${location.region}`;
        if (location.address) {
          this.activeAddress = `${location.address}`;
          if (location.floor) {
            this.activeFloor = `${location.floor}`;
            if (location.room) {
              this.activeRoom = `${location.room}`;
              if (location.workPlace) {
                this.workPlaceNumber = `${location.workPlace}`;
              }
            }
          }
        }
      }
    },
    getValue(value) {
      this.activeRegion = value.region;
      this.activeAddress = value.address;
      this.activeFloor = value.floor;
      this.activeRoom = value.room;
      this.workPlaceNumber = value.workPlace;
    },

    onLocationItemSelect({ item }) {
      if (!item) {
        this.$EsmpNotify.$show('Нужно выбрать адрес из списка', 'attention');
        return;
      }

      this.setLocations(item);
      this.showModal = false;
    },
  },
  watch: {
    value(val) {
      this.val = val;
    },
    employee(employee) {
      this.setVal();
      if (!isEmpty(employee) && employee.login) {
        this.findUserLocation(employee.login);
      } else {
        this.activeRegion = null;
      }
    },
    placementType(value) {
      if (!isEmpty(value)) {
        this.loading = true;
        this.$API.employeeReplace.getFieldsByWorkPlaceType({
          workPlaceTypeFrom: value,
          workPlaceTypeTo: value,
        }).then(({ data }) => {
          if (this.replaceDirection === 'to') {
            this.availableFields = data.replaceToFields;
          }
          if (this.replaceDirection === 'from') {
            this.availableFields = data.replaceFromFields;
          }
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.availableFields = [];
      }
      this.setVal();
    },
    activeRegion(val) {
      if (isEmpty(val)) {
        this.activeAddress = null;
        this.addresses = [];
        this.setVal();
      } else {
        this.loading = true;
        this.setVal();
        const body = {
          region: val,
          isEnabledWorkPlace: this.showPlacementNumber,
        };
        this.$API.employeeReplace.searchWorkPlacesInfo(body).then(({ data }) => {
          this.addresses = data.searchResults.map((r) => ({
            name: r,
          }));
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    activeAddress(val) {
      if (isEmpty(val)) {
        this.activeFloor = null;
        this.floors = [];
        this.setVal();
      } else {
        this.loading = true;
        this.setVal();
        const body = {
          region: this.activeRegion,
          address: this.activeAddress,
          isEnabledWorkPlace: this.showPlacementNumber,
        };
        this.$API.employeeReplace.searchWorkPlacesInfo(body).then(({ data }) => {
          this.floors = data.searchResults.map((r) => ({
            name: r,
          }));
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    activeFloor(val) {
      if (isEmpty(val)) {
        this.activeRoom = null;
        this.rooms = [];
        this.setVal();
      } else {
        this.loading = true;
        this.setVal();
        const body = {
          region: this.activeRegion,
          address: this.activeAddress,
          floor: this.activeFloor,
          isEnabledWorkPlace: this.showPlacementNumber,
        };
        this.$API.employeeReplace.searchWorkPlacesInfo(body).then(({ data }) => {
          this.rooms = data.searchResults.map((r) => ({
            name: r,
          }));
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    activeRoom(val) {
      if (isEmpty(val)) {
        this.workPlaceNumber = '';
        this.workPlaces = [];
        this.setVal();
      } else {
        this.loading = true;
        this.setVal();
        const body = {
          region: this.activeRegion,
          address: this.activeAddress,
          floor: this.activeFloor,
          room: this.activeRoom,
          isEnabledWorkPlace: this.showPlacementNumber,
        };
        this.$API.employeeReplace.searchWorkPlacesInfo(body).then(({ data }) => {
          this.workPlaces = data.searchResults.map((r) => ({
            name: r,
          }));

          if (data.searchResults.length === 1) {
            this.workPlaceNumber = data.searchResults.join();
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    address() {
      this.setVal();
    },
    floor() {
      this.setVal();
    },
    room() {
      this.setVal();
    },
    workPlaceNumber() {
      this.setVal();
    },
    hasAddressProblem(val) {
      if (val) {
        this.hasRoomProblem = false;
      }
      this.setVal();
    },
    hasRoomProblem(val) {
      if (val) {
        this.hasAddressProblem = false;
      }
      this.setVal();
    },
    workPlaces(places) {
      this.$emit('on-workplaces-change', places);
    },
  },
  created() {
    if (!isEmpty(this.value)) {
      this.getValue(this.value);
    }
  },
  mounted() {
    this.getRegions({
      isEnabledWorkPlace: this.showPlacementNumber,
    });
  },
};
</script>

<style lang="scss">
.employee-replacement {
  position: relative;
  padding: 14px 24px;
  border-radius: 12px;
  border: 1px solid #E8E8EE;
  display: flex;
  flex: 1;
  flex-direction: column;
  @include for-size(phone-portrait-down) {
    padding: 7px 12px;
  }
  &__element {
    margin: 10px 0;
    @include for-size(phone-portrait-down) {
      margin: 5px 0;
    }
  }
}
</style>
