/**
 * Разбивает массив на страницы.
 * Страницы могут быть >= 1.
 * @param array {array} - массив, который нужно разбить
 * @param page {number} - номер текущей страницы
 * @param itemsPerPage {number} - кол-во элементов на страницу
 * @return {array} - содержимое массива для нужной страницы
 */
export const getPaginatedArray = (array, page, itemsPerPage) => {
  if (page < 1) {
    throw new Error('Страница должна быть >= 1!');
  }

  return array.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );
};

/**
 * Кол-во страниц.
 * @param total {number} - всегда элементов
 * @param itemsPerPage {number} - кол-во элементов на страницу
 * @return {number} - кол-во страниц
 */
export const getPagesCount = (total, itemsPerPage) => Math.ceil(total / itemsPerPage);
