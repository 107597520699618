<template>
  <div class="employee-replacement-address-list">
    <esmp-input
      v-model="searchQuery"
      label="Поиск..."
      class="employee-replacement-address-list__search-input"
      clearable
    />

    <div class="employee-replacement-address-list__wrapper">
      <div
        v-for="(item, i) in paginatedItems"
        :key="`location${i}`"
        class="employee-replacement-address-list-item"
        v-html="getLocationHtml(item)"
        @click="onLocationItemClick(i, item)"
      />
    </div>

    <div class="employee-replacement-address-list__pagination">
      <esmp-pagination-adaptive
        :current-page.sync="currentPage"
        :page-count="pageCount"
      />
    </div>
  </div>
</template>

<script>
import omit from 'lodash/omit';
import isQueryInValuesOfObject from '@/utils/isQueryInValuesOfObject';
import { getPagesCount, getPaginatedArray } from '@/utils/pagination';

const EMPLOYEE_REPLACEMENT_ADDRESS_PER_PAGE = 10;

export default {
  name: 'EmployeeReplacementAddressList',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      searchQuery: '',
      currentPage: 1,
    };
  },

  computed: {
    filteredItems() {
      return this.searchQuery.length
        ? this.items.filter((item) => isQueryInValuesOfObject(
          omit(item, ['id', 'placeType']),
          this.searchQuery,
        ))
        : this.items;
    },

    paginatedItems() {
      return getPaginatedArray(
        this.filteredItems,
        this.currentPage,
        EMPLOYEE_REPLACEMENT_ADDRESS_PER_PAGE,
      );
    },

    pageCount() {
      return getPagesCount(this.filteredItems.length, EMPLOYEE_REPLACEMENT_ADDRESS_PER_PAGE);
    },
  },

  methods: {
    getLocationHtml(location) {
      const getLocationItemHtml = (label, value) => `
        <div class="employee-replacement-address-list-item__column">
          <span>${label}:</span>
          <strong>${value}</strong>
        </div>`;
      let value = '';

      if (location.region) value += getLocationItemHtml('город', location.region);
      if (location.address) value += getLocationItemHtml('улица', location.address);
      if (location.floor) value += getLocationItemHtml('этаж', location.floor);
      if (location.room) value += getLocationItemHtml('комната', location.room);
      if (location.workPlace) value += getLocationItemHtml('рабочее место', location.workPlace);

      return value;
    },

    onLocationItemClick(index, item) {
      this.$emit('location-item-click', { index, item });
    },
  },

  watch: {
    searchQuery() {
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss">
.employee-replacement-address-list {
  &__search-input,
  &__wrapper {
    margin-bottom: math.div($gap, 2);
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }
}

.employee-replacement-address-list-item {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  transition: background $base-animation;
  border-radius: math.div($base-border-radius, 2);
  position: relative;

  &__column {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px;

    span {
      color: $color-black-op-60;
    }

    > * {
      display: block;
    }
  }

  &:not(:last-child):before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 10px);
    height: 1px;
    background: $color-black-op-25;
  }

  &:hover {
    background: $color-black-op-15;
  }

}

</style>
