<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <search
        v-model="localValue"
        :multiple="multiple"
        :set-login="setLogin"
        :placeholder="placeholder"
        :hint-text="hintText"
        :required="required"
        :hide-results="hideResults"
        :fired="fired"
        :custom-employee-list-id="customEmployeeListId"
        ref="search"
        @select="onSelect"
      />
      <div v-if="v.errors.length || hintText" class="hm-form__element-hint">
        {{ v.errors.length ? v.errors[0] : hintText }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import uid from '@/components/hm-form/mixins/uid';
import Search from './Search.vue';

export default {
  name: 'HmSearch',
  mixins: [uid],
  components: { Search },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    setLogin: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
    hideResults: {
      type: Boolean,
      default: false,
    },
    fired: {
      type: Boolean,
      default: false,
    },
    /**
     * Удалять текст в селекте после выбора?
     */
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    customEmployeeListId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      showSearchResult: false,
      loading: false,
      searchResults: [],
      selectedUsers: [],
      tooMuchResults: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    removeUser(login) {
      this.$refs.search.removeUser(login);
    },

    async onSelect() {
      if (this.clearOnSelect) {
        await this.$nextTick();

        this.$refs.search.clear();
      }
    },
  },
};
</script>
