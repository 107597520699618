var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"employee-replacement"},[_c('esmp-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"size":"large","fix":""}}),(_vm.showEmployee)?_c('div',{class:[
      'employee-replacement__element',
      { 'hm-form__element-wrapper--error': _vm.hasError && !_vm.employee }
    ]},[_c('hm-search',{attrs:{"search-type":"byEmployee","placeholder":"ФИО сотрудника, для кого изменится местоположение"},on:{"input":_vm.setEmployee}})],1):_vm._e(),(_vm.showPlacementType)?_c('div',{class:[
      'employee-replacement__element',
      { 'hm-form__element-wrapper--error': _vm.hasError && !_vm.placementType }
    ]},[_c('esmp-select',{attrs:{"placeholder":"Тип размещения","disabled":_vm.isPlacementTypeDisabled,"filterable":""},model:{value:(_vm.placementType),callback:function ($$v) {_vm.placementType=$$v},expression:"placementType"}},_vm._l((_vm.placementTypeList),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1):_vm._e(),_c('div',{class:[
      'employee-replacement__element',
      { 'hm-form__element-wrapper--error': _vm.hasError && !_vm.activeRegion }
    ]},[_c('esmp-select',{attrs:{"placeholder":"Регион","disabled":_vm.isRegionDisabled,"filterable":""},model:{value:(_vm.activeRegion),callback:function ($$v) {_vm.activeRegion=$$v},expression:"activeRegion"}},_vm._l((_vm.regions),function(item){return _c('esmp-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('div',{class:[
      'employee-replacement__element',
      { 'hm-form__element-wrapper--error': _vm.hasError && !(_vm.hasAddressProblem ? _vm.address : _vm.activeAddress) }
    ]},[(!_vm.hasAddressProblem)?_c('esmp-select',{attrs:{"placeholder":"Адрес","disabled":_vm.isAddressDisabled,"filterable":""},model:{value:(_vm.activeAddress),callback:function ($$v) {_vm.activeAddress=$$v},expression:"activeAddress"}},_vm._l((_vm.addresses),function(item){return _c('esmp-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_c('esmp-input',{attrs:{"label":"Введите адрес","disabled":_vm.isAddressDisabled},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),(_vm.addressChoiceTrouble)?_c('div',{staticClass:"employee-replacement__element"},[_c('esmp-checkbox',{attrs:{"disabled":_vm.hasRoomProblem && _vm.isAddressDisabled},model:{value:(_vm.hasAddressProblem),callback:function ($$v) {_vm.hasAddressProblem=$$v},expression:"hasAddressProblem"}},[_vm._v(" У меня проблема с выбором улицы, дома ")])],1):_vm._e(),_c('div',{class:[
      'employee-replacement__element',
      {
        'hm-form__element-wrapper--error': _vm.hasError
          && !(!_vm.hasAddressProblem && !_vm.hasRoomProblem ? _vm.activeFloor : _vm.floor)
      }
    ]},[(!_vm.hasAddressProblem && !_vm.hasRoomProblem)?_c('esmp-select',{attrs:{"placeholder":"Этаж","disabled":_vm.isFloorDisabled,"filterable":""},model:{value:(_vm.activeFloor),callback:function ($$v) {_vm.activeFloor=$$v},expression:"activeFloor"}},_vm._l((_vm.floors),function(item){return _c('esmp-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_c('esmp-input',{attrs:{"label":"Введите этаж","disabled":!_vm.address && _vm.isFloorDisabled},model:{value:(_vm.floor),callback:function ($$v) {_vm.floor=$$v},expression:"floor"}})],1),(_vm.roomChoiceTrouble)?_c('div',{staticClass:"employee-replacement__element"},[_c('esmp-checkbox',{attrs:{"disabled":_vm.hasAddressProblem && _vm.isRoomDisabled},model:{value:(_vm.hasRoomProblem),callback:function ($$v) {_vm.hasRoomProblem=$$v},expression:"hasRoomProblem"}},[_vm._v(" У меня проблема с выбором этажа, комнаты ")])],1):_vm._e(),_c('div',{class:[
      'employee-replacement__element',
      { 'hm-form__element-wrapper--error': _vm.hasError && !(!_vm.hasAddressProblem && !_vm.hasRoomProblem ? _vm.activeRoom : _vm.room) }
    ]},[(!_vm.hasAddressProblem && !_vm.hasRoomProblem)?_c('esmp-select',{attrs:{"placeholder":"Комната","disabled":_vm.isRoomDisabled,"filterable":""},model:{value:(_vm.activeRoom),callback:function ($$v) {_vm.activeRoom=$$v},expression:"activeRoom"}},_vm._l((_vm.rooms),function(item){return _c('esmp-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_c('esmp-input',{attrs:{"label":"Введите номер комнаты","disabled":!_vm.floor && _vm.isRoomDisabled},model:{value:(_vm.room),callback:function ($$v) {_vm.room=$$v},expression:"room"}})],1),(_vm.showPlacementNumber)?_c('div',{class:[
      'employee-replacement__element',
      { 'hm-form__element-wrapper--error': _vm.hasError && !_vm.workPlaceNumber }
    ]},[(_vm.isWorkPlacesNotEmpty)?_c('esmp-select',{attrs:{"disabled":_vm.isWorkPlaceNumberDisabled,"placeholder":"Номер рабочего места","filterable":""},model:{value:(_vm.workPlaceNumber),callback:function ($$v) {_vm.workPlaceNumber=$$v},expression:"workPlaceNumber"}},_vm._l((_vm.workPlaces),function(item){return _c('esmp-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e()],1):_vm._e(),(false)?_c('p',{staticClass:"employee-replacement__element"},[_vm._v(" Если у Вас проблема с выбором адреса, этажа или кабинета, обратитесь, пожалуйста, к специалисту АХД своего региона. "),_c('br'),_vm._v(" Список специалистов по ссылке: "),_c('a',{attrs:{"href":"https://w3c.portal.rt.ru/files/app#/file/2cce59d7-bbf7-47d0-bcbe-e9034f0096e5","target":"_blank"}},[_vm._v("https://w3c.portal.rt.ru/files/app#/file/2cce59d7-bbf7-47d0-bcbe-e9034f0096e5")])]):_vm._e(),_c('esmp-modal',{attrs:{"title":"Выберите адрес размещения сотрудника","width":"80%"},on:{"on-hidden":_vm.clearEmployeeLocationsList},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('esmp-button',{on:{"click":_vm.onLocationItemSelect}},[_vm._v(" Выбрать ")])]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('employee-replacement-address-list',{attrs:{"items":_vm.employeeLocationsList},on:{"location-item-click":_vm.onLocationItemSelect}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }