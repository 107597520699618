<template>
  <div>
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': tooMuchResults
      }"
    >
      <esmp-select
        v-model="localValue"
        clearable
        filterable
        :multiple="multiple"
        :placeholder="placeholder + (required ? ' *' : '')"
        :show-search-results="showSearchResult"
        :remote-method="debouncedRemoteMethod"
        :loading="loading"
        :max-tag-count="0"
        :max-tag-placeholder="maxTagPlaceholder"
        @on-select="onSelect"
        @on-change="onChange"
        ref="search"
      >
        <esmp-select-option
          v-for="item in searchResults"
          :value="item.login"
          :label="item.fullName"
          :key="item.login"
        >
          <div class="user">
            <esmp-user-avatar
              class="user__avatar"
              :username="item.fullName"
              :image-url="item.photoURL"
            />
            <div class="user__info">
              <div class="user__name">
                <span class="user__name-fio">{{ item.fullName }}</span>
                <span v-if="item.email" class="user__email">{{ item.email }}</span>
              </div>
              <div class="user__appointment-name">
                {{ item.appointmentName }}
              </div>
            </div>
          </div>
        </esmp-select-option>
      </esmp-select>
      <div v-if="tooMuchResults" class="hm-form__element-hint">
        Слишком много результатов. Уточните запрос - добавьте символы
      </div>
    </div>
    <div
      v-if="multiple && selectedUsers && selectedUsers.length && !hideResults"
      class="selected-users"
    >
      <template v-for="(user, index) in selectedUsers">
        <div
          v-if="user"
          :key="`${user.login}_${index}`"
          class="selected-users__item"
        >
          <div class="user">
            <esmp-user-avatar
              class="user__avatar"
              :username="user.fullName"
              :image-url="user.photoURL"
            />
            <div class="user__info">
              <div class="user__name">
                {{ user.fullName }}
                <span v-if="user.email" class="user__email">{{ user.email }}</span>
              </div>
              <div class="user__appointment-name">
                {{ user.appointmentName }}
              </div>
            </div>
          </div>
          <template v-if="setLogin">
            <esmp-input
              v-model="user.customLogin"
              label="Логин"
              class="user__custom-login"
            />
          </template>
          <div
            @click="removeUser(user.login)"
            :class="{
              'selected-users__item-delete': true,
              'selected-users__item-delete_without-input': !setLogin
            }"
          >
            <esmp-icon name="trash" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * Компонент всегда возвращает массив!
 * multiple - это настройка для esmp-select,
 * она не влияет на отдачу результата поиска
*/

import debounce from 'lodash/debounce';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'Search',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hideResults: {
      type: Boolean,
      default: false,
    },
    setLogin: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
    fired: {
      type: Boolean,
      default: false,
    },
    customEmployeeListId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      showSearchResult: false,
      loading: false,
      searchResults: [...this.value],
      selectedUsers: [...this.value],
      tooMuchResults: false,
      localValue: [...this.value].map((u) => u.login),
    };
  },
  methods: {
    remoteMethod(query) {
      if (query !== '' && query.length > 2) {
        this.loading = true;
        this.$API.user.search(
          {
            query,
            isFired: this.fired,
            customEmployeeListId: this.customEmployeeListId,
          },
        ).then((res) => {
          if (res.data.tooMuchResults) {
            this.showSearchResult = false;
            this.tooMuchResults = true;
          } else {
            this.searchResults = res.data.result || res.data;
            this.tooMuchResults = false;
            this.showSearchResult = true;
          }
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.searchResults = [];
      }
    },
    removeUser(login) {
      const selectedUser = this.selectedUsers.find((el) => el.login === login);
      const user = {
        label: selectedUser.fullName,
        value: selectedUser.login,
        tag: undefined,
      };
      this.$refs.search.onOptionClick(user);
    },
    onSelect(v) {
      this.selectedUsers.push(this.searchResults.find((el) => el.login === v.value));
      this.$emit('select', v);
    },
    onChange(v) {
      const arr = this.multiple ? v : [v];
      const result = arr.map((el) => ({
        customLogin: '',
        ...this.selectedUsers.find((item) => item.login === el),
      }));
      this.selectedUsers = result;
      this.showSearchResult = false;
      this.$emit('change', result);
    },
    maxTagPlaceholder(num) {
      if (!num) return '';
      return `Выбрано: ${num}`;
    },

    /**
     * @public
     */
    clear() {
      this.$refs.search.clearSingleSelect();
    },
  },
  created() {
    this.debouncedRemoteMethod = debounce(this.remoteMethod, SEARCH_DELAY);
  },
};
</script>
<style lang="scss" scoped>
.selected-users {
  margin: 20px 0 0 0;
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
    flex-wrap: wrap;
    &-name {
      flex: 1;
    }
    &-delete {
      cursor: pointer;
      margin-left: 20px;
      &_without-input {
        margin-left: auto;
      }
    }
  }
}
.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  @include for-size(phone-portrait-down) {
    width: 100%;
    margin: 0 0 10px 0;
  }
  &__avatar {
    margin: 0 20px 0 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    transition: color 0.3s;
    &-fio {
      margin: 0 16px 0 0;
    }
  }
  &__custom-login {
    margin: 0 0 0 auto;
    @include for-size(phone-portrait-down) {
      margin: 0;
    }
  }
  &__email {
    display: inline-block;
    color: rgba(16, 24, 40, 0.4);
  }
  &__appointment-name {
    color: $color-primary-1-day;
  }
  &__email, &__appointment-name {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
