/**
 * Поиск среди значений объекта.
 * @param object {object} - объект, в значениях которого нужно искать
 * @param query {string} - что искать
 * @returns {boolean} - найдено или нет
 */
const isQueryInValuesOfObject = (object, query) => {
  const values = Object.values(object)
    .filter((value) => ['number', 'string'].includes(typeof value))
    .map((value) => String(value).toLowerCase());

  return values.some((value) => value.includes(query));
};

export default isQueryInValuesOfObject;
